import EventIcon from '@mui/icons-material/Event';
import EventShow from './EventShow';
import EventCreate from './EventCreate';
import EventUpdate from './EventUpdate';

export default {
  show: EventShow,
  create: EventCreate,
  edit: EventUpdate,
  icon: EventIcon,
  options: {
    label: 'Évènements',
  },
};
