import BusinessIcon from '@mui/icons-material/Business';
import GroupList from './GroupList';
import GroupCreate from './GroupCreate';
import GroupEdit from './GroupEdit';

export default {
  list: GroupList,
  create: GroupCreate,
  edit: GroupEdit,
  icon: BusinessIcon,
  options: {
    label: 'Groupes',
    role: 'Admin',
  },
};
