const permissions = {
    // Super Admin
    0: {
        users: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
        groups: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
        entities: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
        events: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
        leads: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
    },
    // Manager
    10: {
        users: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
        groups: {
            enabled: true,
            show: true,
        },
        entities: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
        events: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
        leads: {
            enabled: true,
            show: true,
            edit: true,
            create: true,
            list: true,
        },
    },
    // Responsable
    20: {
        users: {
            enabled: true,
            edit: true,
            create: true,
            list: true,
        },
        groups: {
            enabled: true,
            show: true,
        },
        entities: {
            enabled: true,
            show: true,
            list: true,
        },
        events: {
            enabled: true,
            edit: true,
            create: true,
        },
        leads: {
            enabled: true,
            create: true,
            list: true,
        },
    },
    // Pubeur
    30: {
        users: {
            enabled: true,
        },
        groups: {
            enabled: true,
        },
        entities: {
            enabled: true,
        },
        events: {
            enabled: true,
        },
        leads: {
            enabled: true,
            create: true,
        },
    },
};

export default permissions;
