import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceManyField,
  SingleFieldList,
  FunctionField,
} from "react-admin";
import ChooseButton from './ChooseButton';

const GroupList = props => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="edit">
      <TextField label="Nom" source="name" />
      <ChooseButton
        label="Choisir"
      />
    </Datagrid>
  </List>
);

export default GroupList;
