import * as React from "react";
import EventList from "../Events/EventList";
import { PublisherList } from "../Users/UserList";
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  ResourceContextProvider,
  TopToolbar,
  CreateButton,
} from "react-admin";

const PostTitle = ({ record }) => {
    return <span>{record ? record.name + ' ' : ''}</span>;
};

const CustomCreateButton = ({ basePath }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} label="Créer" />
    </TopToolbar>
);

const EntityShow = props => (
    <Show {...props} title={<PostTitle/>}>
        <TabbedShowLayout>
            <Tab label="Informations">
                <TextField source="name" />
            </Tab>

            <Tab label="Pubeurs" path="publishers">
                <PublisherList actions={<CustomCreateButton basePath="/users" />} />
            </Tab>

            <Tab label="Évènements" path="events">
                <ResourceContextProvider value="events">
                    <EventList basePath="/events" actions={<CustomCreateButton basePath="/events" />} />
                </ResourceContextProvider>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default EntityShow;
