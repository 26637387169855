import * as React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  DateInput,
  Edit,
} from "react-admin";

const EventUpdate = props => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const redirect = (basePath, id, data) => {
        return `/entities/${userData?.entityId}/show/events`;
    };

    return (
        <Edit {...props} >
            <SimpleForm redirect={redirect}>
                <TextInput source="code" disabled />
                <TextInput label="Nom" source="name" validate={required()} />
                <DateInput label="Date" source="date" />
            </SimpleForm>
        </Edit>
    ); 
};

export default EventUpdate;
