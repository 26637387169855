import * as React from "react";
import { useState } from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    RadioButtonGroupInput,
    CheckboxGroupInput,
    useRefresh,
    Toolbar,
    SaveButton,
} from "react-admin";
import { Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import Badge from '@mui/material/Badge';
import moment from 'moment';

import RichTextInput from 'ra-input-rich-text';

const availableOptions = {
    'default': {
        label: 'PASS - L.AS',
        options: [
            { id: '100', name: 'Première santé' },
            { id: '101', name: 'Terminale santé' },
            { id: '102', name: 'P0' },
            { id: '103', name: 'P1 - PASS' },
            { id: '104', name: 'P1 - L.AS' },
        ],
    },
    'CEPSUP': {
        label: 'L1 - SPS',
        options: [
            { id: '201', name: 'Première santé' },
            { id: '202', name: 'Terminale santé' },
            { id: '203', name: 'P0' },
            { id: '204', name: 'P1' },
        ],
    },
    'prepadefi': {
        label: 'PLURIPASS / L-PASS',
        options: [
            { id: '301', name: 'Première santé' },
            { id: '302', name: 'Terminale santé' },
            { id: '303', name: 'P1' },
            { id: '304', name: 'P1 - PluriPASS' },
            { id: '305', name: 'P1 - L-PASS' },
        ],
    },
    'prepavesale': {
        label: 'SPS',
        options: [
            { id: '401', name: 'Première santé' },
            { id: '402', name: 'Terminale santé' },
            { id: '403', name: 'P1' },
        ],
    },
    'segmo&cappec': {
        label: 'PASS – L.AS',
        options: [
            { id: '501', name: 'Première santé' },
            { id: '502', name: 'Terminale santé' },
            { id: '503', name: 'P0' },
            { id: '504', name: 'P1' },
        ],
    }
};

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        right: -20,
        top: 12,
    },
}));

const LeadCreate = props => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const classes = useStyles();
    const CustomInput = props => {
        const currentEvent = props.choices.map(item => {
            if (moment(new Date(item.date)).format('D/M/yyyy') == moment(new Date()).format('D/M/yyyy')) {
                return item;
            }
        });

        return <SelectInput initialValue={currentEvent[0].id} {...props} />
    };
    const CustomIcon = ({ record }) => {
        const position = selectedOptions.findIndex(selectedOption => selectedOption == record.id);

        return (
            <StyledBadge
                badgeContent={position + 1}
                color="primary"
                invisible={selectedOptions.length <= 1 || position < 0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                { record.name }
            </StyledBadge>
        );
    };

    return (
        <Create {...props} >
            <SimpleForm redirect={false} toolbar={<PostCreateToolbar />}>
                <SelectInput
                    label="Pubeur"
                    source="form.publisher"
                    validate={[required()]}
                    initialValue={userData.user_id}
                    choices={[{ id: userData.user_id, name: userData.name }]}
                    disabled
                />

                <ReferenceInput label="Entité" reference="entities" source="entity">
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>

                <ReferenceInput label="Évènement" reference="events" source="form.event">
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>

                <Typography variant="h6" className={classes.title}>Informations étudiant</Typography>
                <TextInput label="Nom" source="form.student.lastName" validate={[required()]} />
                <TextInput label="Prénom" source="form.student.firstName" validate={[required()]} />
                <TextInput label="Email" source="form.student.email" validate={[required()]} />
                <TextInput label="Portable" source="form.student.phone" validate={[required()]} />
                <TextInput label="Adresse" source="form.student.address.street" validate={[required()]} />
                <TextInput label="Code postal" source="form.student.address.postalCode" validate={[required()]} />
                <TextInput label="Ville" source="form.student.address.city" validate={[required()]} />

                <Typography variant="h6" className={classes.title}>Informations parents</Typography>
                <TextInput label="Nom" source="form.parents.lastName" />
                <TextInput label="Prénom" source="form.parents.firstName" />
                <TextInput label="Email" source="form.parents.email" />
                <TextInput label="Portable" source="form.parents.phone" />
                <TextInput label="Adresse" source="form.parents.address.street" />
                <TextInput label="Code postal" source="form.parents.address.postalCode" />
                <TextInput label="Ville" source="form.parents.address.city" />

                <Typography variant="h6" className={classes.title}>Votre formation actuelle</Typography>
                <RadioButtonGroupInput source="form.current.class" label="Classe" choices={[
                    { id: '1', name: 'Seconde' },
                    { id: '2', name: 'Première' },
                    { id: '3', name: 'Terminale' },
                    { id: '4', name: 'Licence' },
                    { id: '0', name: 'Autre' },
                ]} />
                <TextInput
                    label="Autre"
                    source="form.current.class.custom"
                />

                <CheckboxGroupInput source="form.current.options" label="Spécialités" choices={[
                    { id: '0', name: 'Mathématiques' },
                    { id: '1', name: 'Physique – Chimie' },
                    { id: '2', name: 'SVT' },
                    { id: '3', name: 'Sciences de l’ingénieur (SI)' },
                    { id: '4', name: 'Sciences Économiques et Sociales (SES)' },
                    { id: '5', name: 'Numérique et sciences informatiques (NSI)' },
                    { id: '6', name: 'Biologie – Écologie' },
                    { id: '7', name: 'Éducation physique, pratiques et culture sportives' },
                    { id: '8', name: 'Histoire Géographie, Géopolitique et Sciences Politiques' },
                    { id: '9', name: 'Langues, Littératures et cultures étrangères et régionales (LLCER)' },
                    { id: '10', name: 'Littératures, langues et cultures de l’antiquité' },
                    { id: '11', name: 'Humanités, Littérature et Philosophie' },
                    { id: '12', name: 'Arts' },
                ]} />
                <TextInput label="Lycée" source="form.current.school" />

                <Typography variant="h6" className={classes.title}>Formation souhaitée</Typography>
                <CheckboxGroupInput
                    source="form.desiredOptions"
                    label={
                        userData.entityId == 'zz1hgDWHH2ll3uD44RIE' ? availableOptions['CEPSUP'].label :
                        userData.entityId == 'qHlLvl4zNYfZkjZ4rlJK' ? availableOptions['prepadefi'].label :
                        userData.entityId == 'SrdTcNSAFZDNo8e1O2Op' ? availableOptions['prepavesale'].label :
                        userData.entityId == 'kw28arUaZiMngQ4Rt1Aq' ? availableOptions['segmo&cappec'].label :
                        availableOptions['default'].label
                    }
                    row={false}
                    choices={
                        userData.entityId == 'zz1hgDWHH2ll3uD44RIE' ? availableOptions['CEPSUP'].options :
                        userData.entityId == 'qHlLvl4zNYfZkjZ4rlJK' ? availableOptions['prepadefi'].options :
                        userData.entityId == 'SrdTcNSAFZDNo8e1O2Op' ? availableOptions['prepavesale'].options :
                        userData.entityId == 'kw28arUaZiMngQ4Rt1Aq' ? availableOptions['segmo&cappec'].options :
                        availableOptions['default'].options
                    }
                    onChange={ item => setSelectedOptions(item) }
                    optionText={<CustomIcon />}
                />

                <Typography variant="h6" className={classes.title}>Commentaire (optionnel)</Typography>
                <RichTextInput label="" source="form.extraComment" />
            </SimpleForm>
        </Create>
    );
};

const PostCreateToolbar = props => {
    const refresh = useRefresh();

    return (
        <Toolbar {...props}>
            <SaveButton label="Valider" handleSubmit={refresh} />
        </Toolbar>
    );
};

const useStyles = makeStyles({
    title: {
        marginTop: 22,
    },
});

export default LeadCreate;
