import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EntityCreate from './EntityCreate';
import EntityList from './EntityList';
import EntityShow from './EntityShow';

export default {
  list: EntityList,
  show: EntityShow,
  create: EntityCreate,
  icon: HomeWorkIcon,
  options: {
    label: 'Entitées',
  },
};
