import * as React from "react";
import {
  TextField,
  Show,
  SimpleShowLayout,
} from "react-admin";

const EventShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Code" source="code" />
            <TextField label="Nom" source="name" />
        </SimpleShowLayout>
    </Show>
);

export default EventShow;
