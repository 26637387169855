import * as React from "react";
import { Fragment } from 'react';
import {
  Datagrid,
  List,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  SelectInput,
  BulkExportButton,
  BulkDeleteButton,
  useRefresh,
  downloadCSV,
  TopToolbar,
  CreateButton,
  ExportButton,
} from "react-admin";
import jsonExport from 'jsonexport/dist';
import { ImportButton } from "react-admin-import-csv";
import moment from 'moment';

const exporter = leads => {
    const leadsForExport = leads.map(lead => {
        const { form } = lead;
        const result = {
            createdBy: lead.createdBy,
            createDate: lead.createDate,
            student: form.student,
            parents: {},
            extraComment: form.extraComment,
        };

        result.parents = {
            civility: form.parents?.civility?.label,
            lastName: form.parents?.lastName,
            firstName: form.parents?.firstName,
            email: form.parents?.email,
            phone: form.parents?.phone,
        };

        result.currentClass = form.current?.class?.label;
        if (form.current?.classCustom != null) {
            result.currentClass = form.current?.classCustom;
        }

        result.currentSchool = form.current?.school;
        result.currentOptions = form.current?.options?.map(option => option?.label);
        result.desiredOptions = form.desiredOptions?.map(option => option?.label);

        result.nextJpo = form?.nextEventSubscription ? moment(new Date(form?.nextEventSubscriptionDate)).format('D/M/yyyy à HH:mm').toString() : 'Non';
        return result;
    });

    jsonExport(leadsForExport, {
        headers: [
            'student.lastName',
            'student.firstName',
            'student.email',
            'student.address.postalCode',
            'student.address.city',
            'student.address.street',
            'student.phone',
            '',
            'currentSchool',
            'extraComment',
            'nextJpo',
            'parents.civility',
            'parents.lastName',
            'parents.firstName',
            'parents.email',
            'parents.phone',
            'currentClass',
            'currentOptions',
            'desiredOptions',
            'createdBy',
            'createDate'
        ],
        rename: [
            'NOM',
            'PRENOM',
            'MAIL',
            'CP',
            'VILLE',
            'ADRESSE',
            'TEL',
            '',
            'LYCEE',
            'COMMENTAIRE',
            'INSCRIPTION JPO',
            'CIVILITE PARENT',
            'NOM PARENT',
            'PRENOM PARENT',
            'MAIL PARENT',
            'TEL PARENT',
            'CLASSE ACTUELLE',
            'SPECIALITES ACTUELLES',
            'FORMATIONS SOUHAITEES',
            'PUBEUR',
            'AJOUTE LE',
        ],
        rowDelimiter: ';'
    }, (err, csv) => {
        const BOM = '\uFEFF';
        downloadCSV(`${BOM} ${csv}`, 'leads');
    });
};

const refreshView = (field, value) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    userData[field] = value;
    localStorage.setItem('userData', JSON.stringify(userData));
};

const LeadsFilters = props => {
    const refresh = useRefresh();

    return (
        <Filter {...props}>
            <ReferenceInput
                label="Entité"
                reference="entities"
                source="entity"
                sort={{ field: 'name', order: 'ASC' }}
                onChange={e => {
                    refreshView('entityId', e?.target?.value);
                    refresh();
                }}
                alwaysOn>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Évènement"
                reference="events"
                source="form.event"
                sort={{ field: 'date', order: 'DESC' }}
                perPage={999}
                alwaysOn>
                <SelectInput optionText={record => `${record.name} (${record.date})`} locales="fr-FR" />
            </ReferenceInput>
        </Filter>
    )
};

const CustomActions = ({ exporter }) => (
    <TopToolbar>
        <CreateButton label="Créer" />
        { exporter ? <ExportButton exporter={exporter} label="Exporter" /> : null }
    </TopToolbar>
);

const PostBulkActionButtons = props => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    return (
        <Fragment>
            { props.exporter ? <BulkExportButton exporter={props.exporter} label="Exporter" /> : null }
            { userData.role <= 10 ? <BulkDeleteButton undoable={false} {...props} /> : null }
        </Fragment>
    );
};

const LeadList = props => {
    return (
        <List
            {...props}
            empty={props.actions}
            filterDefaultValues={{ entity: null, event: null }}
            filters={<LeadsFilters />}
            bulkActionButtons={<PostBulkActionButtons {...props} exporter={props.exporter ?? exporter} />}
            actions={<CustomActions {...props} exporter={props.exporter ?? exporter} />}
        >
            <Datagrid rowClick="show">
                <TextField label="Id" source="id" />
                <TextField label="Prénom" source="form.student.firstName" />
                <TextField label="Nom" source="form.student.lastName" />
                <TextField label="Support" source="from" />
                <DateField label="Ajouté le" source="createDate" locales="fr-FR" showTime />
            </Datagrid>
        </List>
    );
};

export default LeadList;
