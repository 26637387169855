import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  TopToolbar,
  CreateButton,
} from "react-admin";

const asyncLocalStorage = {
  setItem: async (key, value) => {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async key => {
    await null;
    return localStorage.getItem(key);
  }
};

const EntityCreateButton = () => (
  <TopToolbar>
    <CreateButton basePath="/entities" label="Créer" />
  </TopToolbar>
);

const EntityList = props => {
  let userData = JSON.parse(localStorage.getItem('userData'));

  const handleClick = props => {
    userData.entityId = props;
    return asyncLocalStorage.setItem('userData', JSON.stringify(userData)).then(() => 'show');
  };

  const filters = () => {
    let customFilter = [];
    const a =  userData.entities.map(entity => {
      console.log(entity)
      customFilter = [ ...customFilter, entity ];
      console.log(customFilter)
      return customFilter
    });
    console.log('>>>',  `{{id: PNlVWVbyTFkGpA1Lkgag}, {id: BZUURdrDmZ9GBgBZMlsx}}` )
    return `{{id: PNlVWVbyTFkGpA1Lkgag}, {id: BZUURdrDmZ9GBgBZMlsx}}`;
  };

  return (
    <List
      {...props}
      actions={<EntityCreateButton />}
      perPage={25}
      //filter={{id: 'PNlVWVbyTFkGpA1Lkgag', id: 'BZUURdrDmZ9GBgBZMlsx'}}
      bulkActionButtons={userData.role <= 0 ? true : false}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick={handleClick}>
        <TextField label="Nom" source="name" />
      </Datagrid>
    </List>
  );
};

export default EntityList;
