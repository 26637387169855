import * as React from 'react';
import { Admin } from "react-admin";
import { FirebaseDataProvider, FirebaseAuthProvider } from "react-admin-firebase";
import { ResourceWithPermissions } from 'ra-auth-acl';

import { env as config } from "./config";

import CustomLayout from './CustomLayout';
import users from './Components/Users';
import groups from './Components/Groups';
import entities from './Components/Entities';
import events from './Components/Events';
import leads from './Components/Leads';
import permissions from './permissions';

const options = {
  logging: true,
  metaFieldCasing: 'camel',
  persistence: 'local',
  firestoreCostsLogger: {
    enabled: true,
    //localStoragePrefix // optional
  },
};

const authProvider = FirebaseAuthProvider(config);
let dataProvider = FirebaseDataProvider(config, options);

const myAuthProvider = {
  ...authProvider,
  login: async params => {
    const user = await authProvider.login(params);
    const claims = await authProvider.getPermissions();

    if (claims.role > 0 && claims.entities?.length === 1) {
      claims.entityId = claims.entities[0];
    }

    localStorage.setItem('userData', JSON.stringify(claims));

    return user;
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
        localStorage.removeItem('userData');
        return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return (
      userData ? Promise.resolve() : Promise.reject()
    );
  },
  logout: () => {
    localStorage.removeItem('userData');
    return Promise.resolve();
  },
  getPermissions: async () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const claims = await authProvider.getPermissions();
    if (claims.role !== userData.role) return Promise.reject();

    const rolePermissions = permissions[userData.role];

    return Promise.resolve(rolePermissions);
  },
};

const getRootRef = (resource, groupId, entityId) => {
  let rootRef = '/';

  if (['entities', 'leads'].includes(resource)) {
    rootRef += groupId ? 'groups/' + groupId : '';
  }

  if (['events'].includes(resource)) {
    rootRef += groupId ? 'groups/' + groupId : '';
    rootRef += entityId ? '/entities/' + entityId : '';
  }

  return rootRef;
};

const myDataProvider = {
  ...dataProvider,
  getList: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.getList(resource, params);
  },
  getOne: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.getOne(resource, params);
  },
  getMany: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.getMany(resource, params);
  },
  getManyReference: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.getManyReference(resource, params);
  },
  create: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.create(resource, params);
  },
  update: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.update(resource, params);
  },
  updateMany: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.updateMany(resource, params);
  },
  delete: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.delete(resource, params);
  },
  deleteMany: (resource, params) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const rootRef = getRootRef(resource, params?.target?.groupId ?? userData?.groupId, userData?.entityId);

    dataProvider = FirebaseDataProvider(config, {...options, rootRef});
    return dataProvider.deleteMany(resource, params);
  },
};

const App = () => {
  return (
    <Admin
      layout={CustomLayout}
      dataProvider={myDataProvider}
      authProvider={myAuthProvider}
    >
      {permissions => [
        <ResourceWithPermissions name="entities" permissions={permissions} {...entities} />,
        <ResourceWithPermissions name="users" permissions={permissions} {...users} />,
        <ResourceWithPermissions name="groups" permissions={permissions} {...groups} />,
        <ResourceWithPermissions name="events" permissions={permissions} {...events} />,
        <ResourceWithPermissions name="leads" permissions={permissions} {...leads} />,
      ]}
    </Admin>
  );
}

export default App;
