import * as React from "react";
import {
  TextField,
  Show,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  ArrayField,
  Datagrid,
} from "react-admin";

const LeadShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Informations">
                <TextField label="Id" source="id" />
                <TextField label="Prénom" source="form.student.firstName" />
                <TextField label="Nom" source="form.student.lastName" />
                <TextField label="Support" source="from" />
                <DateField label="Ajouté le" source="createDate" locales="fr-FR" showTime />
            </Tab>

            <Tab label="Parcours" path="events">
                <ArrayField source="timeline">
                    <Datagrid>
                        <DateField label="Date" source="date" locales="fr-FR" showTime />
                        <ReferenceField label="Evènement" source="event" reference="events">
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField label="Commentaire" source="comment" />
                    </Datagrid>
                </ArrayField>
            </Tab>

            <Tab label="Qualité" path="quality">
                <TextField source="teaser" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default LeadShow;
