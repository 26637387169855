import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import LeadShow from './LeadShow';
import LeadCreate from './LeadCreate';
import LeadList from './LeadList';

export default {
  show: LeadShow,
  list: LeadList,
  create: LeadCreate,
  icon: AssignmentIndIcon,
  options: {
    label: 'Leads',
  },
};
