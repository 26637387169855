import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    required,
} from "react-admin";

const EventCreate = props => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const redirect = (basePath, id, data) => {
        return `/entities/${userData.entityId}/show/events`;
    };

    return (
        <Create {...props} >
            <SimpleForm redirect={redirect}>
                <TextInput source="name" validate={required()} />
                <DateInput source="date" />
            </SimpleForm>
        </Create>
    );
};

export default EventCreate;
