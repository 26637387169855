import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
} from "react-admin";

const GroupEdit = props => (
    <Edit {...props} >
        <SimpleForm redirect='list'>
            <TextInput source="name" validate={required()} />
        </SimpleForm>
    </Edit>
);

export default GroupEdit;
