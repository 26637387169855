import * as React from "react";
import { Fragment } from 'react';
import {
  Datagrid,
  List,
  TextField,
  DateField,
  DeleteButton,
  BulkDeleteButton,
} from "react-admin";

const PostBulkActionButtons = props => {
    return (
        <Fragment>
            <BulkDeleteButton undoable={false} {...props} />
        </Fragment>
    );
};

const EventList = props => (
    <List
        {...props}
        empty={props.actions}
        bulkActionButtons={<PostBulkActionButtons />}
        sort={{ field: 'date', order: 'desc' }}
    >
        <Datagrid rowClick="edit">
            <TextField label="Code" source="code" />
            <TextField label="Nom" source="name" />
            <DateField source="date" />
            <DeleteButton undoable={false} redirect={false} label="" />
        </Datagrid>
    </List>
);

export default EventList;
