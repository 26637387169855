import React from 'react';
import { Button, useRefresh } from 'react-admin';

const ChooseButton = props => {
    const { record } = props;
    const refresh = useRefresh();

    const handleClick = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        userData.groupId = record.id;
        localStorage.setItem('userData', JSON.stringify(userData));
        refresh();
    };

    return (
        <Button
            {...props}
            onClick={ e => { e.stopPropagation(); handleClick() } }
        />
    );
};

export default ChooseButton;
