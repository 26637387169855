import { env } from "../../config";

export const validateEmailUnicity = value => {
    return fetch(env['apiUrl'] + '/users-checkEmailIsUnique?email=' + value)
    .then(response => {
        if (response.status === 401) {
            return 'Email déjà existant';
        }

        return undefined;
    })
    .catch(e => {
        return 'Une erreur est survenue';
    })
};
