import * as React from "react";
import { Fragment } from 'react';
import {
  Datagrid,
  List,
  Filter,
  TextInput,
  BooleanField,
  EmailField,
  TextField,
  ReferenceField,
  SelectField,
  ReferenceManyField,
  ListBase,
  Title,
  ListToolbar,
  ReferenceInput,
  SelectInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Pagination,
  useRefresh,
  TopToolbar,
  CreateButton,
  Button,
  useMutation,
  BulkDeleteButton,
} from "react-admin";
import Card from '@mui/material/Card';

import { AVAILABLE_ROLES } from "../../config";

const UserList = props => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  const refreshView = (field, value) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    userData[field] = value;
    localStorage.setItem('userData', JSON.stringify(userData));
  };

  const UserFilters = props => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const refresh = useRefresh();
    
    return (
      <Filter {...props}>
        <TextInput label="Rechercher" source="email" alwaysOn />
        <ReferenceInput
          label="Entitées"
          reference="entities"
          source="entities"
          sort={{ field: 'name', order: 'ASC' }}
          onChange={e => {
            refreshView('entityId', e?.target?.value);
            refresh();
          }}
          alwaysOn>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Filter>
    )
  };

  const CustomActions = () => (
    <TopToolbar>
      <CreateButton label="Créer" />
    </TopToolbar>
  );

  const PostBulkActionButtons = props => {
    const refresh = useRefresh();
    const [mutate, { loading }] = useMutation();
    const enabled = () => {
      props.selectedIds.map(selectedId => {
        mutate({
          type: 'update',
          resource: 'users',
          payload: {
            id: selectedId,
            data: { ...props.record, enabled: true, updatedAt: new Date() }
          },
        });
      });
      refresh();
    };
    const disabled = () => {
      props.selectedIds.map(selectedId => {
        mutate({
          type: 'update',
          resource: 'users',
          payload: {
            id: selectedId,
            data: { ...props.record, enabled: false, updatedAt: new Date() }
          },
        });
      });
      refresh();
    };

    return (
      <Fragment>
        <Button label="Activer" onClick={enabled} disabled={loading} />
        <Button label="Désactiver" onClick={disabled} disabled={loading} />
        <BulkDeleteButton />
      </Fragment>
    )
  };

  return (
    <List
      {...props}
      actions={<CustomActions {...props} />}
      filters={<UserFilters />}
      filter={{ groupId: userData.groupId }}
      bulkActionButtons={<PostBulkActionButtons />}>
      <Datagrid rowClick="edit">
        <BooleanField label="Actif" source="enabled" valueLabelTrue="Oui" valueLabelFalse="Non" />
        <TextField label="Nom" source="lastName" />
        <TextField label="Prénom" source="firstName" />
        <EmailField label="Email" source="email" />
        <SelectField label="Rôle" source="role" choices={AVAILABLE_ROLES} />
      </Datagrid>
    </List>
  )
};

export const PublisherList = props => {
  return (
    <ListBase {...props}>
      <Title title={props.title}/>
      <ListToolbar
        filters={props.filters}
        actions={props.actions}
      />
      <Card>
        <ReferenceManyField label="" reference="users" target="entities">
          <>
            <Datagrid rowClick="edit">
              <BooleanField label="Actif" source="enabled" valueLabelTrue="Oui" valueLabelFalse="Non" />
              <TextField label="Nom" source="lastName" />
              <TextField label="Prénom" source="firstName" />
              <EmailField label="Email" source="email" />
            </Datagrid>
            <Pagination />
          </>
        </ReferenceManyField>
      </Card>
    </ListBase>
  )
};

export default UserList;
