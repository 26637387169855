import * as React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  Edit,
  BooleanInput,
  SelectInput,
  PasswordInput,
  ReferenceInput,
  AutocompleteArrayInput,
  useDataProvider,
} from "react-admin";
import { AVAILABLE_ROLES } from "../../config";
import { useFormState } from 'react-final-form';

const UserUpdate = props => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    let displayEntities = [];
    let selectedGroupId = null;

    const EntitiesSelectInput = props => {
        const dataProvider = useDataProvider();
        const { values } = useFormState();
        if (selectedGroupId && selectedGroupId !== values.groupId) {
            values.entities = null;
        }

        selectedGroupId = values.groupId;
        dataProvider.getList('entities',
        {
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'name', order: 'ASC' },
            target: { groupId: values.groupId }
        }, {}).then(result => {
            displayEntities = result?.data;
        });
    
        return (
            <AutocompleteArrayInput label="Entitées" source="entities" choices={displayEntities} translateChoice={false} {...props} />
        );
    };

    return (
        <Edit {...props} >
            <SimpleForm>
                <BooleanInput label="Compte actif" source="enabled" defaultValue={true} />
                <TextInput label="Nom" source="lastName" validate={[required()]} />
                <TextInput label="Prénom" source="firstName" validate={[required()]} />
                <TextInput label="Email" source="email" validate={[required()]} />
                <PasswordInput label="Mot de passe" source="password" validate={[required()]} />
                <SelectInput
                    label="Rôle"
                    source="role"
                    validate={[required()]}
                    choices={AVAILABLE_ROLES}
                    initialValue={30}
                    disabled={userData.role > 10}
                />
                <ReferenceInput label="Groupe" source="groupId" reference="groups">
                    <SelectInput
                        optionText="name"
                        disabled={userData.role > 0}
                        initialValue={userData.groupId ?? null}
                    />
                </ReferenceInput>
                <EntitiesSelectInput />
            </SimpleForm>
        </Edit>
    )
};

export default UserUpdate;
