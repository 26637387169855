import PeopleIcon from '@mui/icons-material/People';
import UserList from './UserList';
import UserCreate from './UserCreate';
import UserUpdate from './UserUpdate';

export default {
  list: UserList,
  create: UserCreate,
  edit: UserUpdate,
  icon: PeopleIcon,
  options: {
    label: 'Utilisateurs',
  },
};
