import * as React from "react";
import { useCallback } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectInput,
    PasswordInput,
    ReferenceInput,
    AutocompleteArrayInput,
    required,
    email,
    minLength,
    useMutation,
    useDataProvider,
    useRefresh,
} from "react-admin";
import { useFormState } from 'react-final-form';
import { AVAILABLE_ROLES } from "../../config";
import { validateEmailUnicity } from './Validation';

const UserCreate = props => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    let displayEntities = [];
    let selectedGroupId = null;
    const [mutate] = useMutation();
    const refresh = useRefresh();

    const save = useCallback(
        async values => {
            const errors = {};
            const errorEmail = await validateEmailUnicity(values.email);
            if (errorEmail) {
                errors.email = errorEmail;
            }

            if (Object.keys(errors).length > 0) {
                return errors;
            }

            try {
                await mutate({
                    type: 'create',
                    resource: 'users',
                    payload: { data: values },
                }, { returnPromise: true });
                refresh();
            } catch (error) {
                if (error.body.errors) {
                    return error.body.errors;
                }
            }
        },
        [mutate],
    );

    const EntitiesSelectInput = props => {
        const dataProvider = useDataProvider();
        const { values } = useFormState();
        if (selectedGroupId && selectedGroupId !== values.groupId) {
            values.entities = null;
        }

        selectedGroupId = values.groupId;
        dataProvider.getList('entities',
        {
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'name', order: 'ASC' },
            target: { groupId: values.groupId }
        }, {}).then(result => {
            displayEntities = result?.data;
        });
    
        return (
            <AutocompleteArrayInput label="Entitées" source="entities" choices={displayEntities} translateChoice={false} {...props} />
        );
    };

    return (
        <Create {...props} >
            <SimpleForm save={save}>
                <BooleanInput label="Compte actif" source="enabled" defaultValue={true} />
                <TextInput label="Nom" source="lastName" validate={[required(), minLength(2)]} />
                <TextInput label="Prénom" source="firstName" validate={[required(), minLength(2)]} />
                <TextInput label="Email" source="email" validate={[email(), required()]} />
                <PasswordInput label="Mot de passe" source="password" validate={[required()]} />
                <SelectInput
                    label="Rôle"
                    source="role"
                    validate={[required()]}
                    choices={AVAILABLE_ROLES}
                    initialValue={30}
                    disabled={userData.role > 10}
                />
                <ReferenceInput
                    label="Groupe"
                    source="groupId"
                    reference="groups"
                    validate={[required()]}
                    initialValue={userData.groupId}
                    filter={userData.role > 0 ? {id: userData.groupId} : null}>
                    <SelectInput optionText="name" disabled={userData.role > 0} />
                </ReferenceInput>
                <EntitiesSelectInput />
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;
