import * as React from 'react';
import { AppBar, useGetOne, TextField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    textField: {
        fontWeight: 'bold',
        fontSize: 18,
    }
});

const CustomAppBar = props => {
    const classes = useStyles();
    const userData = JSON.parse(localStorage.getItem('userData'));
    const { data, loaded } = useGetOne('groups', userData?.groupId);
    if (!loaded) return <span>Loading</span>;


    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            <div>
                {!loaded ? <span>Chargement</span> : <TextField className={classes.textField} record={data} source="name" />}
            </div>

            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
