export const env = {
    name: "RELEASE",
    apiKey: "AIzaSyDQ1uXsZxBIcTbEfq7mTUzsukPc0dO5gV8",
    authDomain: "supexam-85754.firebaseapp.com",
    projectId: "supexam-85754",
    storageBucket: "supexam-85754.appspot.com",
    messagingSenderId: "1067421525982",
    appId: "1:1067421525982:web:e1a47ca330d8eada429f4f",
    apiUrl: 'https://us-central1-supexam-85754.cloudfunctions.net'
};
